// import introspectionResult from '../../generated/graphql.schema.json'
import {
  buildClientSchema,
  IntrospectionQuery,
  GraphQLScalarType,
  Kind,
} from 'graphql'
import { withScalars } from 'apollo-link-scalars'

/* eslint-disable */
const introspectionResult = require('../../generated/graphql.schema.json')
/* eslint-enable */
const schema = buildClientSchema(
  introspectionResult as unknown as IntrospectionQuery,
)

const typesMap = {
  // example of scalar type, which will parse the string into a Date object
  Date: new GraphQLScalarType({
    name: 'Date',
    serialize: (parsed: Date | null) => parsed && parsed.getTime(),
    parseValue: (raw: any) => raw && new Date(raw),
    parseLiteral(ast) {
      if (ast.kind === Kind.STRING || ast.kind === Kind.INT) {
        return new Date((ast as any).value)
      }
      return null
    },
  }),
}

const scalarsLink = withScalars({
  schema,
  typesMap,
})

export default scalarsLink
