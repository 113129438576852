import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100vh;
  }
  body {
    margin: 0;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.mui.palette.background.default};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: ${({ theme }) => theme.mui.shape.borderRadius}px;
    background: ${({ theme }) => theme.mui.palette.text.hint};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.mui.palette.primary.main};
  }

  .CodeMirror-overlayscroll-horizontal div, .CodeMirror-overlayscroll-vertical div {
    background: ${({ theme }) => theme.mui.palette.text.hint} !important;
  }
`

export default GlobalStyle
