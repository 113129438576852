import React, { createContext, useCallback, useContext, useState } from 'react'
import { GlobalAlert, GlobalAlertWithKey } from './Alert'
import Alerts from './Alerts'

const defaultValue = {
  alerts: [] as GlobalAlertWithKey[],
  pushAlert: (alert: GlobalAlert /* eslint-disable-line */) => {
    //
  },
}

const AlertContext = createContext(defaultValue)
AlertContext.displayName = 'Alert'

export const useGlobalAlert = () => {
  const globalAlert = useContext(AlertContext)
  return globalAlert
}

interface Props {
  children: React.ReactNode
}

const AlertProvider: React.FC<Props> = (props: Props) => {
  const { children } = props
  const [alerts, setAlerts] = useState<GlobalAlertWithKey[]>([])
  const pushAlert = useCallback(
    (alert: GlobalAlert) => {
      const newAlert: GlobalAlertWithKey = {
        ...alert,
        key: `${new Date().getTime()}-${Math.random()}`,
      }
      setAlerts((alerts) => [...alerts, newAlert])
    },
    [setAlerts],
  )
  return (
    <AlertContext.Provider
      value={{
        alerts,
        pushAlert,
      }}
    >
      <Alerts alerts={alerts} setAlerts={setAlerts} />
      {children}
    </AlertContext.Provider>
  )
}

export default AlertProvider
