import React, { lazy, Suspense } from 'react'
import GraphQLProvider from '../../components/GraphQLProvider'
import IntlProvider from '../../components/IntlProvider'
import GlobalStyle from '../../globalStyle'
import AuthenticationProvider from '../../components/Authentication'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@aristech/components'
import PageLoader from '../../components/PageLoader'
import AlertProvider from '../../components/AlertProvider'

import '@aristech/components/lib/fonts.css'
import '@aristech/components/lib/icons.css'
import { BASE_URL } from '../../variables'

const Studio = lazy(() => import('../Studio'))
const Batch = lazy(() => import('../Batch'))
const Settings = lazy(() => import('../Settings'))
const Docs = lazy(() => import('../Docs'))

const App = () => {
  return (
    <AuthenticationProvider>
      <GraphQLProvider>
        <IntlProvider>
          <ThemeProvider>
            <CssBaseline />
            <AlertProvider>
              <Router basename={BASE_URL}>
                <Suspense fallback={<PageLoader />}>
                  <Switch>
                    <Route path="/loader">
                      <PageLoader />
                    </Route>
                    <Route path="/docs">
                      <Docs />
                    </Route>
                    <Route path="/settings/:panel">
                      <Settings />
                    </Route>
                    <Route path="/settings">
                      <Settings />
                    </Route>
                    <Route path="/batch/:voiceId">
                      <Batch />
                    </Route>
                    <Route path="/batch">
                      <Batch />
                    </Route>
                    <Route path="/studio/:voiceId">
                      <Studio />
                    </Route>
                    <Route path="/">
                      <Studio />
                    </Route>
                  </Switch>
                </Suspense>
              </Router>
            </AlertProvider>
            <GlobalStyle />
          </ThemeProvider>
        </IntlProvider>
      </GraphQLProvider>
    </AuthenticationProvider>
  )
}

export default App
