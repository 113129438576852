import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Stack } from '@aristech/components'
import Alert, { GlobalAlertWithKey } from './Alert'

const Wrapper = styled(Stack)`
  position: fixed;
  top: 64px;
  right: 0;
  min-width: 600px;
  max-width: 100vw;
  z-index: 100000;
  padding: ${({ theme }) => theme.mui.spacing()}px;
  padding-bottom: 0;
`

interface Props {
  alerts: GlobalAlertWithKey[]
  setAlerts: React.Dispatch<React.SetStateAction<GlobalAlertWithKey[]>>
}

const Alerts: React.FC<Props> = (props: Props) => {
  const { alerts, setAlerts } = props
  const remove = useCallback(
    (idx: number) => {
      setAlerts((alerts) => {
        const newAlerts = [...alerts]
        newAlerts.splice(idx, 1)
        return newAlerts
      })
    },
    [setAlerts],
  )
  return (
    <Wrapper>
      {alerts.map((alert, idx) => {
        return <Alert {...alert} key={alert.key} remove={() => remove(idx)} />
      })}
    </Wrapper>
  )
}

export default Alerts
