import React, { useCallback, useEffect, useRef, useState } from 'react'
import { AlertTitle } from '@material-ui/lab'
import { Alert as MAlert } from '@aristech/components'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import { useHoverDirty } from 'react-use'

type Severity = 'error' | 'warning' | 'info' | 'success'

export interface GlobalAlertWithKey {
  severity: Severity
  showTime?: number
  closable?: boolean
  title?: React.ReactNode
  children: React.ReactNode
  key: string
}

export type GlobalAlert = Omit<GlobalAlertWithKey, 'key'>

const defaultShowTimes = {
  error: -1,
  warning: 5000,
  info: 3000,
  success: 3000,
}

const AlertComponent = styled(MAlert)`
  box-shadow: ${({ theme }) => theme.mui.shadows[3]};
  &.alert-appear {
    opacity: 0;
    transform: translateX(600px);
  }
  &.alert-appear-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  &.alert-appear-done {
    opacity: 1;
    transform: translateX(0);
  }
  &.alert-exit {
    opacity: 1;
  }
  &.alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
`

interface Props extends GlobalAlert {
  remove: () => void
}

const Alert: React.FC<Props> = (props: Props) => {
  const { severity } = props
  const {
    title,
    children,
    showTime = defaultShowTimes[severity],
    closable = true,
    remove,
  } = props
  const alertRef = useRef<HTMLElement>(null)
  const isHovering = useHoverDirty(alertRef)
  const [show, setShow] = useState(true)
  const close = useCallback(() => {
    setShow(false)
  }, [setShow])
  useEffect(() => {
    if (!isHovering && showTime >= 0) {
      const timer = setTimeout(close, showTime)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [showTime, close, isHovering])
  return (
    <CSSTransition
      in={show}
      appear={show}
      timeout={300}
      classNames="alert"
      unmountOnExit
      onExited={remove}
    >
      <AlertComponent
        onClose={closable ? close : undefined}
        severity={severity}
        ref={alertRef}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {children}
      </AlertComponent>
    </CSSTransition>
  )
}

export default Alert
