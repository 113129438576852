import { useMemo } from 'react'

/* eslint-disable no-console */
export const LOG_LEVELS = ['TRACE', 'DEBUG', 'INFO', 'WARN', 'ERROR'] as const

type LogLevelType = typeof LOG_LEVELS
export type LogLevel = LogLevelType[number]

class Logger {
  private component: string

  constructor(component: string) {
    this.component = component
  }

  trace(msg: string, ...rest: any) {
    if (Logger.getLogLevelNumber() > LOG_LEVELS.indexOf('TRACE')) {
      return
    }
    const { component } = this
    console.trace(
      `%c[${component}]%c [TRACE] %c${msg}`,
      'color: grey',
      'color: grey',
      '',
      ...rest,
    )
  }

  debug(msg: string, ...rest: any) {
    if (Logger.getLogLevelNumber() > LOG_LEVELS.indexOf('DEBUG')) {
      return
    }
    const { component } = this
    console.debug(
      `%c[${component}]%c [DEBUG] %c${msg}`,
      'color: grey',
      'color: grey',
      '',
      ...rest,
    )
  }

  info(msg: string, ...rest: any) {
    if (Logger.getLogLevelNumber() > LOG_LEVELS.indexOf('INFO')) {
      return
    }
    const { component } = this
    console.info(
      `%c[${component}]%c [INFO] %c${msg}`,
      'color: grey',
      'color: green',
      '',
      ...rest,
    )
  }

  warn(msg: string, ...rest: any) {
    if (Logger.getLogLevelNumber() > LOG_LEVELS.indexOf('WARN')) {
      return
    }
    const { component } = this
    console.warn(
      `%c[${component}]%c [WARN] %c${msg}`,
      'color: yellow',
      'color: green',
      '',
      ...rest,
    )
  }

  error(msg: string, ...rest: any) {
    if (Logger.getLogLevelNumber() > LOG_LEVELS.indexOf('ERROR')) {
      return
    }
    const { component } = this
    console.error(
      `%c[${component}]%c [ERROR] %c${msg}`,
      'color: red',
      'color: green',
      '',
      ...rest,
    )
  }

  group(label: string, fn: () => void) {
    console.groupCollapsed(label)
    fn()
    console.groupEnd()
  }

  private static getLogLevelNumber() {
    return LOG_LEVELS.indexOf(Logger.getLoglevel())
  }

  private static DEFAULT_LOG_LEVEL: LogLevel = 'WARN'

  private static getLoglevel(): LogLevel {
    try {
      const logLevelJson =
        localStorage.getItem('logLevel') || Logger.DEFAULT_LOG_LEVEL
      const logLevelStr = JSON.parse(logLevelJson) as LogLevel
      if (
        typeof logLevelStr === 'string' &&
        LOG_LEVELS.indexOf(logLevelStr) > -1
      ) {
        return logLevelStr
      }
    } catch (err) {
      //
    }
    return Logger.DEFAULT_LOG_LEVEL
  }
}

export const useLogger = (component: string) => {
  const logger = useMemo(() => new Logger(component), [component])
  return logger
}

export default Logger
