import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
  from,
  split,
} from '@apollo/client'
import { GRAPHQL_HTTP_URI, GRAPHQL_WS_URI } from '../../variables'
import React, { useEffect, useMemo } from 'react'

import Logger from '../../utils/logger'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { WebSocketLink } from '@apollo/client/link/ws'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from '@apollo/client/utilities'
import { onError } from '@apollo/client/link/error'
import scalarsLink from './customScalars'
import { useAuthentication } from '../Authentication'

const logger = new Logger('GraphQL')

interface CreateClientOptions {
  logout: () => void
}
const createClient = (options: CreateClientOptions) => {
  let client: ApolloClient<NormalizedCacheObject> | null = null

  // const httpLink = new HttpLink({ uri: GRAPHQL_HTTP_URI })
  const httpLink = createUploadLink({
    uri: GRAPHQL_HTTP_URI,
  })
  const authLink = new ApolloLink((operation, forward) => {
    // Retrieve the authorization token from local storage.
    const token = localStorage.getItem('auth_token')

    // Use the setContext method to set the HTTP headers.
    if (token) {
      operation.setContext(({ headers }: { headers: any[] }) => ({
        headers: {
          authorization: `bearer ${token}`,
          ...headers,
        },
      }))
    }

    // Call the next link in the middleware chain.
    return forward(operation)
  })
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (
          /not authenticated/i.test(message) ||
          /jwt expired/i.test(message) ||
          /invalid algorithm/i.test(message)
        ) {
          logger.info('Authentication expired...')
          const { logout } = options
          logout()
          return
        }
        logger.group(`GraphQL Error: ${message}`, () => {
          logger.error(`Message: ${message}`)
          logger.error('Locations:', locations)
          logger.error('Path:', path)
        })
      })
    }
    if (networkError) {
      logger.error('Network error', networkError)
    }
  })
  const wsLink = new WebSocketLink({
    uri: GRAPHQL_WS_URI,
    options: {
      reconnect: true,
      connectionParams: () => ({
        authorization: `bearer ${localStorage.getItem('auth_token')}`,
      }),
    },
  })
  const subscriptionClient = (wsLink as any)
    .subscriptionClient as SubscriptionClient
  subscriptionClient.onConnecting(() => {
    logger.debug('Subscription link: connecting')
  })
  subscriptionClient.onConnected(() => {
    logger.debug('Subscription link: connected')
  })
  subscriptionClient.onReconnecting(() => {
    logger.debug('Subscription link: reconnecting')
  })
  subscriptionClient.onReconnected(() => {
    logger.debug('Subscription link: reconnected')
  })
  subscriptionClient.onDisconnected(() => {
    logger.debug('Subscription link: disconnected')
  })
  subscriptionClient.onError((err: Error) => {
    logger.error('Subscription error: ', err)
  })

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink as any,
  )

  client = new ApolloClient({
    link: from([authLink, errorLink, scalarsLink as any, splitLink]),
    cache: new InMemoryCache(),
  })
  return { client, wsLink }
}

interface Props {
  children: React.ReactNode
}

const GraphQLProvider = ({ children }: Props) => {
  const { logout, authenticated } = useAuthentication()
  const { client } = useMemo(() => createClient({ logout }), [logout])
  // Reset store when authenticated switches to false
  useEffect(() => {
    if (!client || authenticated) {
      return
    }
    logger.debug('Reseting store...')
    client.resetStore()
  }, [client, authenticated])
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default GraphQLProvider
