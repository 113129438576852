/**
 * When the app is started in development mode
 * the variables are provided as REACT_APP_<VARNAME>
 * In production, the variables are injected from express
 * without the REACT_APP_ prefix (@see index.html).
 */
import Logger from './utils/logger'

const NODE_ENV = process.env.NODE_ENV
const REACT_APP_BASENAME = process.env.REACT_APP_BASENAME || ''
const REACT_APP_API_BASENAME = process.env.REACT_APP_API_BASENAME || ''
const REACT_APP_TTS_BASENAME = process.env.REACT_APP_TTS_BASENAME || ''
const REACT_APP_VERSION = process.env.REACT_APP_VERSION || 'unknown'

const { BASENAME = '/', API_BASENAME = '/', TTS_BASENAME = '' } = window as any

const isDev = NODE_ENV === 'development'

/**
 * Adds a single trailing slash to the end of a path
 */
const tslash = (path: string) => path.replace(/\/*$/, '/')

export const BASE_URL = tslash(isDev ? REACT_APP_BASENAME : BASENAME)

const API_BASE_URL = tslash(isDev ? REACT_APP_API_BASENAME : API_BASENAME)
// http => ws and https => wss
const API_BASE_URL_WS = API_BASE_URL.replace('http', 'ws')

// TTS runs on a different port when not reverse proxied
const TTS_BASE_URL = tslash(
  isDev ? REACT_APP_TTS_BASENAME : TTS_BASENAME || API_BASE_URL,
)
const TTS_BASE_URL_WS = TTS_BASE_URL.replace('http', 'ws')

export const GRAPHQL_HTTP_URI = `${API_BASE_URL}graphql`
export const GRAPHQL_WS_URI = `${API_BASE_URL_WS}graphql`
export const TTS_HTTP_URI = `${TTS_BASE_URL}tts`
export const TTS_WS_URI = `${TTS_BASE_URL_WS}stream`
export const BATCH_HTTP_URI = `${API_BASE_URL}batch_archive`
// Version doesn't change after the app was built
export const VERSION = REACT_APP_VERSION

const logger = new Logger('Variables')
logger.debug('Variables', {
  BASE_URL,
  GRAPHQL_HTTP_URI,
  GRAPHQL_WS_URI,
  TTS_HTTP_URI,
  TTS_WS_URI,
  BATCH_HTTP_URI,
  VERSION,
})
