import React from 'react'
import ReactDOM from 'react-dom'
import App from './containers/App'
import * as serviceWorker from './serviceWorker'

if (!Intl.RelativeTimeFormat) {
  // require('@formatjs/intl-relativetimeformat/polyfill')
  // require('@formatjs/intl-relativetimeformat/dist/locale-data/en')
  // require('@formatjs/intl-relativetimeformat/dist/locale-data/de-DE')
}

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
