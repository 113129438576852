import { IconLoader } from '@aristech/components'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

interface Props {
  label?: string
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.mui.palette.background.default};
`
const LoaderWrapper = styled.div`
  width: 200px;
`
const Label = styled.h1`
  text-align: center;
  padding-top: 1.5rem;
  color: ${(props) => props.theme.mui.palette.text.disabled};
  font-size: 1.5rem;
`

const PageLoader: React.FC<Props> = (props: Props) => {
  const intl = useIntl()
  const { label = intl.formatMessage({ id: 'page_loader.loading' }) } = props
  return (
    <Wrapper>
      <LoaderWrapper>
        <IconLoader />
        <Label>{label}</Label>
      </LoaderWrapper>
    </Wrapper>
  )
}

export default PageLoader
