import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { IntlProvider as IntlBaseProvider } from 'react-intl'
// import en from '../../translations/en.json'
// import de from '../../translations/de.json'
import { useLocalStorage } from 'react-use'
import { ReactComponent as BritishFlag } from '../../images/flags/en-GB.svg'
import { ReactComponent as GermanFlag } from '../../images/flags/de-DE.svg'
import { shouldPolyfill } from '@formatjs/intl-relativetimeformat/should-polyfill'
import Logger, { useLogger } from '../../utils/logger'

async function polyfill(locale: string) {
  const logger = new Logger('IntlProvider')
  const doPolyfill = shouldPolyfill()
  logger.debug('Intl.RelativeTimeFormat polyfill is needed:', doPolyfill)
  if (doPolyfill) {
    // Load the polyfill 1st BEFORE loading data
    await import('@formatjs/intl-relativetimeformat/polyfill')
  }

  if ((Intl.RelativeTimeFormat as any).polyfilled) {
    switch (locale) {
      default:
        logger.debug('Polyfilling Intl.RelativeTimeFormat with locale', 'en')
        await import('@formatjs/intl-relativetimeformat/locale-data/en')
        break
      case 'de-DE':
        logger.debug('Polyfilling Intl.RelativeTimeFormat with locale', 'de-DE')
        await import('@formatjs/intl-relativetimeformat/locale-data/de')
        break
    }
  }
}
polyfill('en')

/**
 * Fixes the casing in browser locales as some browsers like safari return lowercase only like de-de
 * @param locale Locale string
 * @returns Fixed locale string with lowercase language and uppercase location (e.g. de-de => de-DE)
 */
const fixBrowserLocale = (locale: string) =>
  locale.replace(
    /^([a-z]+)-([a-z]+)$/i,
    (_, m1, m2) => `${m1.toLowerCase()}-${m2.toUpperCase()}`,
  )

/* eslint-disable */
const en = require('../../translations/en.json')
const de = require('../../translations/de.json')
/* eslint-enable */

const autoLocale: string = fixBrowserLocale(
  (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    (navigator as any).userLanguage ||
    'en-GB',
)

const messages = {
  'en-GB': en,
  'de-DE': de,
  de,
}

const allLocales = [
  {
    locale: 'en-GB',
    labelId: 'locales.english',
    Flag: BritishFlag,
  },
  {
    locale: 'de-DE',
    labelId: 'locales.german',
    Flag: GermanFlag,
  },
]

const defaultValue = {
  allLocales,
  /**
   * Whether the locale was manually set
   * or is autodetected based on the browser settings
   */
  isAutoLocale: false,
  locale: autoLocale,
  // eslint-disable-next-line
  setLocale: (locale: string) => {
    //
  },
  /**
   * Resets locale to autodetect based on the browser setting
   */
  setAutoLocale: () => {
    //
  },
}

export const LocaleContext = createContext(defaultValue)
LocaleContext.displayName = 'LocaleContext'

export const useLocale = () => {
  const locale = useContext(LocaleContext)
  return locale
}

interface Props {
  children: React.ReactNode
  locale?: string
}

const IntlProvider: React.FC<Props> = (props: Props) => {
  const { children /* , locale = autoLocale */ } = props
  const logger = useLogger('IntlProvider')
  const [lsLocale, setLocale] = useLocalStorage<string | null>('locale', null)
  const locale = lsLocale || autoLocale
  const isAutoLocale = !lsLocale
  const localizedMessages =
    locale === 'en-GB' ? en : Object.assign({}, en, (messages as any)[locale])
  const setAutoLocale = useCallback(() => {
    setLocale(null)
    localStorage.removeItem('locale')
  }, [setLocale])
  useEffect(() => {
    logger.debug('Switching locale to', locale)
    const html = document.getElementsByTagName('html')
    if (html.length) {
      html[0].setAttribute('lang', locale)
    }
    polyfill(locale)
  }, [locale])
  const value = useMemo(
    () => ({ allLocales, isAutoLocale, locale, setLocale, setAutoLocale }),
    [allLocales, isAutoLocale, locale, setLocale, setAutoLocale],
  )
  return (
    <LocaleContext.Provider value={value}>
      <IntlBaseProvider
        messages={localizedMessages}
        locale={locale}
        defaultLocale="en"
      >
        {children}
      </IntlBaseProvider>
    </LocaleContext.Provider>
  )
}

export default IntlProvider
