import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
export interface AuthData {
  token: string
  tokenTTL: Date
}

const hasValidToken = () => {
  const token = localStorage.getItem('auth_token')
  if (!token) {
    return false
  }
  const tokenTTLStr = localStorage.getItem('auth_token_valid_until')
  if (!tokenTTLStr) {
    return false
  }
  const tokenTTL = new Date(parseInt(tokenTTLStr, 10))
  const now = new Date()
  return now.getTime() < tokenTTL.getTime()
}

const runAtDate = (date: Date, fn: () => void) => {
  const now = new Date().getTime()
  const then = date.getTime()
  const diff = Math.max(then - now, 0)
  if (diff > 0x7fffffff) {
    // setTimeout limit is MAX_INT32=(2^31-1)
    setTimeout(() => {
      runAtDate(date, fn)
    }, 0x7fffffff)
  }
  setTimeout(fn, diff)
}

const defaultValue = {
  authenticated: false,
  // eslint-disable-next-line
  login: (data: AuthData) => {
    //
  },
  logout: () => {
    //
  },
}

export const AuthenticationContext = createContext(defaultValue)
AuthenticationContext.displayName = 'Authentication'

export const useAuthentication = (/* onLogout?: () => void */) => {
  const authentication = useContext(AuthenticationContext)
  return authentication
}

interface Props {
  children: React.ReactNode
}

const AuthenticationProvider: React.FC<Props> = (props: Props) => {
  const { children } = props
  const [authenticated, setAuthenticated] = useState(hasValidToken())

  const logout = useCallback(() => {
    setAuthenticated(false)
    localStorage.removeItem('auth_token')
    localStorage.removeItem('auth_token_valid_until')
  }, [setAuthenticated])

  // Logout when token has expired
  useEffect(() => {
    // Logout when authenticatedVar was changed (e.g. by the GraphQL ErrorLink)
    if (!authenticated) {
      logout()
      return
    }
    // Logout when token expired
    const tokenTTLStr = localStorage.getItem('auth_token_valid_until')
    if (!tokenTTLStr) {
      logout()
      return
    }
    const tokenTTL = new Date(parseInt(tokenTTLStr, 10))
    runAtDate(tokenTTL, logout)
  }, [authenticated, logout])

  return (
    <AuthenticationContext.Provider
      value={{
        authenticated,
        login: ({ token, tokenTTL }) => {
          localStorage.setItem('auth_token', token)
          localStorage.setItem(
            'auth_token_valid_until',
            `${tokenTTL.getTime()}`,
          )
          setAuthenticated(true)
        },
        logout,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}

export default AuthenticationProvider
